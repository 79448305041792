<template>
  <v-dialog
      v-model="dialoge"
      max-width="700"
      persistent
  >
    <v-card>
      <v-card-text>
        <div class="row pt-1 border-bottom">
          <div class="col-md-12">
            <div class="d-flex justify-space-between align-center mt-2">
              <v-spacer/>
              <v-btn  fab x-small class="shadow-0" @click="()=>{this.$emit('closePreviewModal')}">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-text class="pa-5 pt-0"  >

        <iframe :srcDoc="previewContent" style="height: 1250px; width: 100% ; overflow: hidden" />
      </v-card-text>
      <v-card-actions>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "EmailPreviewModal",
  props:{
    dialoge: { type: Boolean, default: false },
    previewContent: { type: String, default: null },
    slug: { type: String, default: '' },
  },
  data() {
    return {
      content :''
    }
  },


  mounted(){
    // this.loadEmailTemplatePreview(this.templateId);
  },

  methods:{
    loadEmailTemplatePreview(id) {
      this.showLoader("Loading");
      let url = `venues/marketing/template-preview/${id}`;

      this.$http
          .get(url)
          .then((response) => {
            console.log(response)
            this.content = response.data;
            this.hideLoader();
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
  }

};
</script>

<style>

</style>
