<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {CustomUploadAdapterPlugin} from "@/plugins/UploadAdapter";

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    contentsLangDirection: {
      type: String,
      default: 'ltr',
    },
    language: {
      type: String,
      default: 'en',
    },

  },
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [(editor) => CustomUploadAdapterPlugin(editor, this.handleLoader)],
        mediaEmbed: {previewsInData: true},
        language: this.language,
        contentsLangDirection: this.contentsLangDirection,
      },
    };
  },
  methods: {
    input(val) {
      this.$emit('input', val);
    },
    handleLoader(loader) {
      if (loader) {
        this.showLoader("Uploading Image...")
      } else {
        this.hideLoader();
      }
    },
  },
};
</script>

<template>
  <div>
    <ckeditor :config="{...editorConfig,...config}" :editor="editor" :value="value" @input="input" />
  </div>
</template>

